<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
            :show="isLoading"
            rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Văn bằng, chứng chỉ"
                      label-for="credentialId"
                  >
                    <v-select
                        v-model="filter.credentialId"
                        :options="credentials"
                        :reduce="option => option.value"
                        @input="onCredentialChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Khung thời gian triển khai"
                      label-for="certificateImplementId"
                  >
                    <v-select
                        v-model="filter.certificateImplementId"
                        :options="certificateImplements"
                        :reduce="option => option.value"
                        @input="onCertificateImplementChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    v-if="credentialType === 0"
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Điều kiện đạt"
                      label-for="credentialConditionId"
                  >
                    <v-select
                        v-model="filter.credentialConditionId"
                        :options="credentialConditions"
                        :reduce="option => option.value"
                        @input="onCredentialConditionChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Mẫu văn bằng, chứng chỉ"
                      label-for="credentialSampleId"
                  >
                    <v-select
                        v-model="filter.credentialSampleId"
                        :options="credentialSamples"
                        :reduce="option => option.value"
                        @input="onCredentialSampleChange"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <hr class="invoice-spacing">
              <b-row>
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Hệ đào tạo"
                      label-for="trainingSystemId"
                  >
                    <v-select
                        v-model="filter.trainingSystemId"
                        :options="trainingSystems"
                        :reduce="option => option.value"
                        @input="onTrainingSystemsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Khóa học"
                      label-for="courseId"
                  >
                    <v-select
                        v-model="filter.courseId"
                        :options="courses"
                        :reduce="option => option.value"
                        @input="onCoursesChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    v-if="credentialType === 0"
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Học kỳ"
                      label-for="courseSemesterId"
                  >
                    <v-select
                        v-model="filter.courseSemesterId"
                        :options="courseSemester"
                        :reduce="option => option.value"
                        @input="onCourseSemesterChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    v-if="credentialType === 1"
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Khoa/Bộ môn"
                      label-for="departmentId"
                  >
                    <v-select
                        v-model="filter.departmentId"
                        :options="departments"
                        :reduce="option => option.value"
                        @input="onDepartmentChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    v-if="credentialType === 1"
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Ngành học"
                      label-for="majorId"
                  >
                    <v-select
                        v-model="filter.majorId"
                        :options="majors"
                        :reduce="option => option.value"
                        @input="onMajorChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    v-if="credentialType === 1"
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="Lớp cố định"
                      label-for="classId"
                  >
                    <v-select
                        v-model="classId"
                        :options="classes.data"
                        :reduce="option => option.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <div class="text-left">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        @click="createCodesCredentials"
                    >
                      <feather-icon icon="AnchorIcon" /> Đánh số hiệu
                    </b-button>
                  </div>
                </b-col>
                <b-col cols="6">
                  <div class="text-right">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="ml-1"
                        @click="onGetList"
                    >
                      <feather-icon icon="FilterIcon" /> Lấy danh sách
                    </b-button>
                    <b-button
                        v-if="createCodeStatus"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-primary"
                        class="ml-1"
                        @click="onCreateCredential"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="FilePlusIcon" /> Tạo và nhập kho
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mt-2">
              <b-col cols="12">
                <div>
                  <vue-good-table
                      mode="remote"
                      class="my-table"
                      style-class="vgt-table striped bordered"
                      row-style-class="vgt-row"
                      :columns="credentialType === 0 ? columns : columnsDiploma"
                      :rows="studentsPassConditionList"
                      :pagination-options="paginationOptions"
                      :select-options="{ enabled: true, selectOnCheckboxOnly: true, selectionText: 'SV được chọn', clearSelectionText: 'Bỏ chọn' }"
                      @on-selected-rows-change="selectionChanged"
                  >
                    <div
                        slot="emptystate"
                        style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                        slot="table-row"
                        slot-scope="props"
                    >
                      <span v-if="props.column.field === 'stt'">
                        {{ props.row.originalIndex + 1 }}
                      </span>
                      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    </template>
                  </vue-good-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem, BForm, BFormGroup,
  BFormSelect, BOverlay, BPagination, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'

export default {
  name: 'CreateCredential',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 3000,
        credentialId: '',
        credentialConditionId: '',
        credentialSampleId: '',
        credentialSampleFileId: '',
        organizationId: '',
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        departmentId: '',
        majorId: '',
        programmeId: '',
        subjectId: '',
        status: '',
        certificateImplementId: '',
        yearId: '',
      },
      filterAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
      codeCredential: '',
      codeCredentialStorage: '',
      credentialType: '',
      classId: null,
      paginationOptions: {
        enabled: false,
      },
      studentsPassConditionList: [],
      createCodeStatus: 0,
      addCredentialStorageStatus: 0,
      credentialName: '',
      courseName: '',
      courseSemesterName: '',
      columns: [
        {
          label: 'STT',
          field: 'stt',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Mã học viên',
          field: 'code',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Họ và tên',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngày sinh',
          field: 'birthday',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Lớp học',
          field: 'className',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Loại văn bằng, chứng chỉ',
          field: 'credentialName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      columnsDiploma: [
        {
          label: 'STT',
          field: 'stt',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Mã học viên',
          field: 'code',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Họ và tên',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngày sinh',
          field: 'birthday',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Lớp học',
          field: 'className',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Loại văn bằng, chứng chỉ',
          field: 'credentialName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Xếp loại',
          field: 'graduationText',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      dataListTest: [
        {
          full_name: 'Nguyen Van A',
          studentCode: '123',
          gender: 'Nam',
          birthday: '01/01/1990',
          address: 'Ha Noi',
          typeStudy: 'Dai hoc',
          graduation_text: 'Gioi',
          course: 'Khoa 1',
          day: '06',
          month: '06',
          year: '2024',
          SVS: '12345',
          codeCredentialStorage: '',
          code: '',
        },
        {
          full_name: 'Nguyen Van B',
          studentCode: '234',
          gender: 'Nam',
          birthday: '02/02/1990',
          address: 'Ha Noi',
          typeStudy: 'Dai hoc',
          graduation_text: 'Gioi',
          course: 'Khoa 1',
          day: '06',
          month: '06',
          year: '2024',
          SVS: '23456',
          codeCredentialStorage: '',
          code: '',
        },
      ],
      selectedMainData: [],
      credentialsTest: [
        {
          value: 1,
          label: 'Chứng chỉ ngoại ngữ',
        },
        {
          value: 2,
          label: 'Bằng tốt nghiệp cử nhân',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      studentsPassCondition: 'credentialCreate/studentsPassCondition',
      dataLists: 'credentialCreate/dataLists',
      classes: 'credentialCreate/classes',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      graduationType: 'graduationCondition/graduationType',
      courseSemester: 'dropdown/courseSemesters',
      credentials: 'dropdown/credentials',
      credentialConditions: 'dropdown/credentialConditions',
      credentialSamples: 'dropdown/credentialSamples',
      totalRows: 'credentialCreate/totalRows',
      credentialsStorage: 'credentialCreate/credentialsStorage',
      certificateImplements: 'dropdown/certificateImplements',
      certificateImplement: 'certificateImplement/certificateImplement',
    }),

  },
  async created() {
    this.isLoading = true
    try {
      const cUser = getUser()
      this.filter.organizationId = (cUser.orgId ? cUser.orgId : 0)
      await Promise.all([
        this.getTrainingSystems({ organizationId: this.filter.organizationId }),
        this.getDepartments({ organizationId: this.filter.organizationId }),
        this.getAllCredentials(this.filterAll),
      ])
      if (this.trainingSystems.length > 0) {
        this.filter.trainingSystemId = this.trainingSystems[0].value
        await this.getCourses(this.filter)
      }
      if (this.credentials.length > 0) {
        this.filter.credentialId = this.credentials[0].value
        this.credentialName = this.credentials.find(credentials => credentials.value === this.filter.credentialId).label
        await Promise.all([
          this.getCredentialAllSamples({
            currentPage: 1,
            itemsPerPage: 1000,
            credentialId: this.filter.credentialId,
          }),
          this.getCertificateImplements({
            currentPage: 1,
            itemsPerPage: 1000,
          }),
        ])
        const credentialTypeId = await this.getCredentialById(this.filter.credentialId)
        const credentialTypeData = await this.getCredentialTypeById(credentialTypeId.data.data.credentialTypeId)
        this.credentialType = credentialTypeData.data.data.type
      }
      this.$store.commit('graduationCondition/SET_DATA', { records: [] })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getData: 'credentialCreate/getData',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getClasses: 'credentialCreate/getClasses',
      getCourseSemestersByCourseId: 'dropdown/getCourseSemestersByCourseId',
      getAllCredentials: 'dropdown/getAllCredentials',
      getCredentialAllConditions: 'dropdown/getCredentialAllConditions',
      getCredentialAllSamples: 'dropdown/getCredentialAllSamples',
      getStStudentsPassCondition: 'credentialCreate/getStStudentsPassCondition',
      createCredential: 'credentialCreate/createCredential',
      getCredentialSample: 'credentialSample/getCredentialSample',
      addCredentialsStorage: 'credentialCreate/addCredentialsStorage',
      getCertificateBlankById: 'certificateBlank/getCertificateBlankById',
      getCredentialById: 'credential/getCredentialById',
      getCredentialTypeById: 'credentialType/getCredentialTypeById',
      uploadSample: 'certificateBlank/uploadSample',
      getCredentialsStorageByInfoStudent: 'credentialCreate/getCredentialsStorageByInfoStudent',
      getCertificateImplements: 'dropdown/getCertificateImplements',
      getCertificateImplement: 'certificateImplement/getCertificateImplement',
    }),
    async onCredentialChange(event) {
      this.filter.credentialId = event
      this.filter.credentialConditionId = null
      this.filter.credentialSampleId = null
      this.filter.certificateImplementId = null
      this.filter.courseId = null
      this.filter.courseSemesterId = null
      this.courseName = ''
      this.courseSemesterName = ''
      await Promise.all([
        this.getCredentialAllSamples({
          currentPage: 1,
          itemsPerPage: 1000,
          credentialId: this.filter.credentialId,
        }),
      ])
      const credentialTypeId = await this.getCredentialById(this.filter.credentialId)
      const credentialTypeData = await this.getCredentialTypeById(credentialTypeId.data.data.credentialTypeId)
      this.credentialType = credentialTypeData.data.data.type
      this.credentialName = this.credentials.find(credentials => credentials.value === event).label
    },
    async onCertificateImplementChange(event) {
      this.filter.certificateImplementId = event
      await this.getCredentialAllConditions({
        currentPage: 1,
        itemsPerPage: 1000,
        credentialId: this.filter.credentialId,
        certificateImplementId: this.filter.certificateImplementId,
      })
      await this.getCertificateImplement({
        currentPage: 1,
        itemsPerPage: 1000,
        id: this.filter.certificateImplementId,
      })
    },
    async onCredentialConditionChange(event) {
      this.filter.credentialConditionId = event
    },
    async onCredentialSampleChange(event) {
      this.filter.credentialSampleId = event
      const credentialSample = await this.getCredentialSample(event)
      this.filter.credentialSampleFileId = credentialSample.data.data.credentialSampleFileMainId
      const certificateBlank = await this.getCertificateBlankById(credentialSample.data.data.certificateBlankId)
      this.codeCredential = certificateBlank.data.data.codeCredential
      this.codeCredentialStorage = certificateBlank.data.data.codeCredentialStorage
      this.codeCredential = certificateBlank.data.data.codeCredential
    },
    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      this.courseName = ''
      this.courseSemesterName = ''
      await this.getCourses(this.filter)
    },
    async onCoursesChange(event) {
      this.filter.courseId = event
      this.courseName = this.courses.find(course => course.value === event).label
      this.filter.courseSemesterId = null
      this.courseSemesterName = ''
      await this.getCourseSemestersByCourseId({ courseId: this.filter.courseId })
    },
    async onCourseSemesterChange(event) {
      this.filter.courseSemesterId = event
      this.courseSemesterName = this.courseSemester.find(courseSemester => courseSemester.value === event).label
      this.filter.yearId = this.courseSemester.find(courseSemester => courseSemester.value === event).yearId
    },
    async onDepartmentChange(event) {
      this.filter.departmentId = event
      this.filter.majorId = null
      await this.getMajors(this.filter)
    },
    async onMajorChange(event) {
      this.filter.majorId = event
      this.filter.programmeId = null
      await this.getClasses(this.filter)
    },
    selectionChanged(params) {
      this.selectedMainData = params ? params.selectedRows.map(e => (
          {
            name: e.name,
            credentialId: e.credentialId || this.filter.credentialId,
            credentialSampleId: this.filter.credentialSampleId || '',
            studentId: e.id,
            studentCode: e.code,
            code: '',
            codeCredentialStorage: '',
            credentialIssuanceId: e.credentialIssuanceId || '',
            birthday: e.birthday,
            address: e.address,
            majorId: e.majorId || '',
            classId: e.classId || '',
            trainingSystemId: this.filter.trainingSystemId,
            genderCode: e.genderCode || null,
            ethnicCode: e.ethnicCode || '',
            nationality: e.nationality || '',
            graduationText: e.graduationText || '',
            yearId: e.yearId || this.filter.yearId || '',
            credentialFileId: e.credentialFileId || '',
            statusPrint: 0,
            score: e.score || '',
            year: e.year || '',
            status: 1,
          }
      )) : []
    },
    async onCreateCredential() {
      if (this.selectedMainData.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn Học viên!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return null
      }
      this.isLoading = true
      try {
        const request = this.selectedMainData

        const responseAdd = await this.addCredentialsStorage(request)
        if (responseAdd) {
          const { isSuccessful, message } = responseAdd
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
        const responseCreate = await this.createCredential({ request, sampleFileId: this.filter.credentialSampleFileId })
        if (responseCreate && responseCreate.data) {
          const blob = new Blob([responseCreate.data], { type: 'application/octet-stream' })
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          const nameZip = this.filter.credentialName
          link.setAttribute('download', 'students_certificate.zip')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tải file không thành công',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.addCredentialStorageStatus = 1
        this.isLoading = false
      }

      return null
    },
    async onSelectDegree() {
      this.isLoading = true
      try {
        await this.getData({
          class_id: this.classId,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    transformData(data) {
      return data.map(item => ({
        name: item.full_name,
        graduationText: item.graduation_text,
        code: item.student_code,
        id: item.student_id,
        birthday: item.birthday,
        score: item.CGPA_4,
      }))
    },
    parseDate(dateStr) {
      if (typeof dateStr !== 'string') {
        throw new Error('Invalid date string')
      }
      const parts = dateStr.split('-')
      return new Date(
          parseInt(parts[0], 10),
          parseInt(parts[1], 10) - 1,
          parseInt(parts[2], 10),
      )
    },
    isCurrentDateInRange(startDateStr, endDateStr) {
      const startDate = this.parseDate(startDateStr)
      const endDate = this.parseDate(endDateStr)

      const currentDate = new Date()
      const currentMonthDay = { month: currentDate.getMonth() + 1, day: currentDate.getDate() }

      const startMonthDay = { month: startDate.getMonth() + 1, day: startDate.getDate() }
      const endMonthDay = { month: endDate.getMonth() + 1, day: endDate.getDate() }

      const currentNumber = currentMonthDay.month * 100 + currentMonthDay.day
      const startNumber = startMonthDay.month * 100 + startMonthDay.day
      const endNumber = endMonthDay.month * 100 + endMonthDay.day

      if (startNumber <= endNumber) {
        return currentNumber >= startNumber && currentNumber <= endNumber
      }
      return currentNumber >= startNumber || currentNumber <= endNumber
    },
    async onGetList() {
      if (this.filter.certificateImplementId !== '' && !this.isCurrentDateInRange(this.certificateImplement[0].timeImplement, this.certificateImplement[0].timeEnd)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hiện tại không phải thời gian triển khai',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.createCodeStatus = 0
      this.addCredentialStorageStautus = 0
      this.isLoading = true
      try {
        if (this.credentialType === 0) {
          await this.getStStudentsPassCondition({
            credential_condition_id: this.filter.credentialConditionId,
            courses_semesters_id_fk: this.filter.courseSemesterId,
            id_credential_fk: this.filter.credentialId,
          })
          this.studentsPassConditionList = this.studentsPassCondition
        } else if (this.credentialType === 1) {
          await this.getData({
            class_id: this.classId,
          })
          const dataList = this.transformData(this.dataLists)
          this.studentsPassConditionList = dataList
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onUpdate(event) {
    },
    createCodesCredentials() {
      if (this.selectedMainData.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn Học viên!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return null
      }
      this.selectedMainData.forEach((item, index) => {
        const Stt = (index + 1).toString().padStart(5, '0')
        const yearStr = item.year.toString()
        item.codeCredentialStorage = `${this.codeCredentialStorage}${yearStr}${Stt}`
        item.code = `${this.codeCredential}${yearStr}${Stt}`
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Đánh số hiệu thành công',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      this.createCodeStatus = 1
      return null
    },

    async printCredentials() {
      if (this.selectedMainData.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn Học viên!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return null
      }
      await this.getCredentialsStorageByInfoStudent(this.selectedMainData)
      return null
    },
  },
}
</script>
<style scoped lang="scss">

</style>
